import { FinishedWorkOrder, WorkOrderLine } from '@tag/graphql';

export enum WorkOrderDocumentType {
  released = 'Released',
  planned = 'Planned',
}

export interface WorkOrderUpsert {
  complete?: boolean;
  crew_Code?: string;
  description?: string;
  document_Type: string;
  due_By_Date?: Date;
  ending_Datetime?: Date;
  enterprise?: string;
  equipment_ID?: string;
  expiration_Date?: Date;
  facility?: string;
  feedback?: WorkOrderLineFeedback[];
  job_Line_Type?: string;
  job_No?: string;
  job_Task_No?: string;
  maint_Type?: string;
  no: string;
  order_Date?: Date;
  order_Type?: string;
  osp_No?: string;
  personnel_Group?: string;
  post?: boolean;
  priority_Rank?: string;
  priority?: string;
  problem_Code?: string;
  region?: string;
  requested_Service_Date?: Date;
  starting_Datetime?: Date;
  status?: string;
  supervisor_Code?: string;
  technician_Code?: string;
  usages: WorkOrderLineUsage[];
  web_Service_User?: string;
  billing_Type?: 'both' | 'labour' | 'usage';
  work_Code?: string;
  approval_Pending?: boolean;
  outages?: Array<WorkOrderOutage>;
  work_Order_Lines: BatchWorkOrderLinePost[];
}

export interface WorkOrderOutage {
  Line_No: number;
  Outage_Code: string;
  Downtime_Start: Date;
  Downtime_End: Date;
}

export interface WorkOrderLineUsage {
  actual_Quantity?: number;
  actual_Unit_Cost?: number;
  bin_Code?: string;
  description_2?: string;
  description?: string;
  document_Type: string;
  entry_Type?: string;
  expected_Quantity?: number;
  facility?: string;
  line_No: number;
  location_Code?: string;
  lookup_Type?: string;
  no: string;
  pending_Quantity?: number;
  post?: boolean;
  posted_Quantity?: number;
  requirement_Details?: string;
  source_Line_No: number;
  source_No: string;
  type: string;
  unit_of_Measure?: string;
  variant_Code?: string;
  vendor_No?: string;
  wo_Reference_Info?: string;
}

export interface WorkOrderLineFeedback {
  document_Line_No?: number;
  document_No?: string;
  document_Type?: string;
  extended_Feedback?: boolean;
  failure_Code?: string;
  feedback_Text?: string;
  feedback_Type?: string;
  line_No?: number;
  table_Name?: string;
  technician_Code?: string;
}

export interface BatchWorkOrderLinePost {
  actual_Time?: number;
  area?: string;
  binary_Negative_Value?: string;
  binary_Positive_Value?: string;
  booking_Line_No?: number;
  current_Meter?: number;
  description?: string;
  document_Type?: string;
  due_By_Date?: Date;
  ending_Datetime?: Date;
  enterprise?: string;
  equipment_Description?: string;
  equipment_ID?: string;
  estimated_Time?: number;
  expand_Substeps_to_WO_Line?: boolean;
  expiration_Date?: Date;
  facility?: string;
  failure_Code?: string;
  feedback?: WorkOrderLineFeedback;
  finished?: boolean;
  job_Line_Type?: string;
  job_No?: string;
  job_Task_No?: string;
  line_No: number;
  personnel_Group?: string;
  priority?: string;
  rate_Type?: string;
  region?: string;
  requested_Service_Date?: Date;
  result_Datetime?: Date;
  result_Test_Description?: string;
  result_Type?: string;
  results_Accepted?: boolean;
  results_Input_Required?: boolean;
  results_Value?: string;
  starting_Datetime?: Date;
  technician_Code?: string;
  union_No?: string;
  outages?: Array<WorkOrderOutage>;
  usages: WorkOrderLineUsage[];
  web_Service_User?: string;
  work_Code?: string;
  work_Order_No?: string;
  work_Procedure_Step?: string;
}

export interface WorkOrderFromTemplatePost {
  description?: string;
  equipment_ID: string;
  start_Date?: Date;
  template_No?: string;
  workOrder_No: string;
}

export interface WorkOrderFromRequestPost {
  copy_AddDesc?: number;
  osp_No?: string;
  personnel_Group?: string;
  request_No?: string;
  supervisor?: string;
  tech?: string;
  template_No?: string;
  work_Procedure?: string;
  workOrder_No?: string;
  woStatus?: string;
}

export interface BatchWorkOrderEdit {
  due_By_Date?: Date;
  maintenance_Type?: string;
  order_Type?: string;
  personnel_Group?: string;
  priority_Ranking?: string;
  priority?: string;
  project_Contract_No?: string;
  rate_Type?: string;
  requested_Service_Date?: Date;
  starting_DateTime?: Date;
  status?: string;
  technician_Code?: string;
  union_No?: string;
  update_WO_Line?: boolean;
  work_Orders: {
    work_Order_No: string;
    document_Type: string;
  }[];
}

export interface BatchWorkOrderReview {
  work_Orders: Array<WorkOrderReview>;
}

export interface WorkOrderReview {
  action: string;
  comment?: string;
  formId?: string;
  status?: string;
  technician_Code?: string;
  web_Service_User?: string;
  work_Order_No: string;
}

export interface ReportWOMaintenance {
  complete?: boolean;
  document_Type?: string;
  feedback?: Array<WorkOrderLineFeedback>;
  finished_Work_Order_No?: string;
  order_Type?: string;
  status?: string;
  technician_Code?: string;
  usages?: Array<WorkOrderLineUsage>;
  web_Service_User?: string;
  work_Code?: string;
  work_Order_Lines?: Array<BatchWorkOrderLinePost>;
  work_Order_No?: string;
}

export interface ReportWOMaintenanceReturn {
  workOrderNo: string;
  workOrderLines?: Array<WorkOrderLine>;
  finishedWorkOrderNo?: string;
}

export interface ErrorAndFinishedWorkOrderResult {
  errors?: Array<string>;
  finishedWorkOrders?: Array<FinishedWorkOrder>;
  workOrders?: Array<WorkOrderReviewPostResponse>;
}

export interface WorkOrderReviewPostResponse {
  action: string;
  comment?: string;
  postingErrorText?: string;
  status?: string;
  technicianCode?: string;
  webServiceUser?: string;
  workOrderNo: string;
}
