import type { WorkOrder } from '@tag/graphql';
import {
  BatchWorkOrderEdit,
  BatchWorkOrderReview,
  ReportWOMaintenance,
  WorkOrderDocumentType,
  WorkOrderFromRequestPost,
  WorkOrderFromTemplatePost,
  WorkOrderUpsert,
} from '@api/types';
import { Operation } from 'fast-json-patch';

/**
 * Add Work Order
 *
 * @param payload Work Order object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class AddWorkOrder {
  static readonly type = '[WorkOrder] Add';

  constructor(public payload: WorkOrder) {}
}

/**
 * Upsert Work Order
 *
 * @param payload Work Order object
 */
export class UpsertWorkOrder {
  static readonly type = '[WorkOrder] Upsert';

  constructor(public payload: WorkOrderUpsert) {}
}

/**
 * Add Work Order From Template
 *
 * @param payload Work Order Template Post object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class AddWorkOrderFromTemplate {
  static readonly type = '[WorkOrder] Add Template';

  constructor(
    public payload: WorkOrderFromTemplatePost,
    public selectedItem?: WorkOrder | null
  ) {}
}

/**
 * Add Work Order
 *
 * @param payload Work Order From Request Post Object
 */
export class AddWorkOrderFromRequest {
  static readonly type = '[WorkOrder] Add from Request';

  constructor(public payload: WorkOrderFromRequestPost) {}
}

/**
 * Get all Work Orders
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetWorkOrders {
  static readonly type = '[WorkOrder] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get a single Work Order
 *
 * @param no Work Order unique ID
 */
export class GetWorkOrder {
  static readonly type = '[WorkOrder] GetSingle';

  constructor(
    public no: string,
    public woType: 'released' | 'planned' | 'finished' = 'released'
  ) {}
}

/**
 * Get summary work order
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetSummaryWorkOrders {
  static readonly type = '[WorkOrder] Get Summary';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get paginated Work Order Lines
 *
 * @param filter OData filter
 */
export class GetPaginatedWorkOrders {
  static readonly type = '[WorkOrder] Get Paginated';

  constructor(
    public woType: 'released' | 'planned' | 'finished',
    public top: number,
    public skip: number,
    public filter?: string,
    public orderBy?: string,
    public desc?: boolean
  ) {}
}

/**
 * Update Work Order, this method will dynamically update the cache Work Order list and selected Work Order.
 *
 * @param no Work Order No
 * @param patch JSON patch object used to update the Work Order. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateWorkOrder {
  static readonly type = '[WorkOrder] Update';

  constructor(
    public no: string,
    public patch: Operation[],
    public type: WorkOrderDocumentType = WorkOrderDocumentType.released,
    public selectedItem?: WorkOrder | null
  ) {}
}

/**
 * Update multiple Work Orders, this method will dynamically update the cache Work Order list and selected Work Order.
 *
 * @param workOrderBatch Work Order batch Numbers and patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateWorkOrders {
  static readonly type = '[WorkOrder] Update Multiple';

  constructor(
    public workOrderBatch: BatchWorkOrderEdit,
    public selectedItem?: WorkOrder
  ) {}
}

/**
 * Update Work Order Universal Document Number, this method will dynamically update the cache Work Order list and selected Work Order.
 *
 * @param no Work Order No
 * @param patch JSON patch object used to update the Work Order. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateWorkOrderUniversalDocumentNo {
  static readonly type = '[WorkOrder] Update Universal Document No';

  constructor(public woNo: string, public udnNo: string) {}
}

/**
 * Complete Work Order Line, this method will dynamically update the cache Work Order list and selected Work Order.
 *
 * @param no Work Order No
 */
export class CompleteWorkOrder {
  static readonly type = '[WorkOrder] Complete';

  constructor(public no: string) {}
}

/**
 * Delete Work Order, this method will dynamically update the cache Work Order list and set selected Work Order to null.
 *
 * @param no Work Order No
 */
export class DeleteWorkOrder {
  static readonly type = '[WorkOrder] Delete';

  constructor(public no: string) {}
}

/**
 * Set selected Work Order Line
 *
 * @param payload New Work Order Line full object. Summary is not supported.
 */
export class SetSelectedWorkOrder {
  static readonly type = '[WorkOrder] Set';

  constructor(public payload: WorkOrder | null) {}
}

/**
 * Set multiple selected Work Order Line
 *
 * @param payload List of new Work Order Line full object. Summary is not supported.
 */
export class SetSelectedWorkOrders {
  static readonly type = '[WorkOrder] Set Multiple';

  constructor(public payload: WorkOrder[]) {}
}

/**
 * Batch Review Work Order.
 *
 * @param payload List of Review Post Object.
 */
export class ReviewWorkOrders {
  static readonly type = '[WorkOrder] Batch Review';

  constructor(public payload: BatchWorkOrderReview) {}
}

/**
 * Report Work Order Maintenance.
 *
 * @param payload Report Post Object.
 */
export class ReportWorkOrder {
  static readonly type = '[WorkOrder] Report Maintenance';

  constructor(public payload: ReportWOMaintenance) {}
}

/**
 * Update work order type
 *
 * @param workOrder Work order object
 */
export class UpdateWorkOrderType {
  static readonly type = '[WorkOrder] Update Type';

  constructor(public workOrder: WorkOrder) {}
}
